import dayjs from 'dayjs';
import {
  formatDateNoDay,
  formatDateWithFullYear,
  formatDateWithFullYearAndFullTime,
} from '../../../../utils/DateUtil';

export const sortByTimeStamp = (a, b) =>
  dayjs(b.createdTimestamp).valueOf() > dayjs(a.createdTimestamp).valueOf()
    ? 1
    : -1;

export const sortByInspection = (a, b) =>
  dayjs(b.inspectionTimestamp).valueOf() >
  dayjs(a.inspectionTimestamp).valueOf()
    ? 1
    : -1;

export const transformAndFilterReportsData = (reports, includeTime = true) => {
  const transformItem = (item) => ({
    round: item.round,
    assessmentId: item.id,
    date: includeTime
      ? formatDateWithFullYearAndFullTime(item.inspectionTimestamp)
      : formatDateWithFullYear(item.inspectionTimestamp),
  });

  // transform data
  return reports.sort(sortByTimeStamp).map(transformItem);
};

export const reportsFormattedSmartShop = (reports) => {
  const transformItem = (item) => ({
    round: item.round,
    assessmentId: item.id,
    date: formatDateNoDay(item.inspectionTimestamp),
    inspectionTimestamp: item.inspectionTimestamp,
  });

  return reports.map(transformItem).sort(sortByInspection);
};

import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  LoadingIndicator,
  TextField,
  Typography,
} from 'cfa-react-components';
import queryString from 'query-string';
import { API_GET, formatDateNoDayComma } from '../../../utils';
import AppealsCardContainer from '../../../components/Appeals/AppealsCardContainer';
import FilterBoxContainer from '../../../components/common/FilterBox/FilterBoxContainer';
import I18n from '../../../../i18n/utils';

import './HistoricalAppeals.scss';
import { searchObject } from '../../../utils/filters';

const domain = process.env.REACT_APP_SERVER_URL;

const HistoricalAppeals = () => {
  const [appeals, setAppeals] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [uniqueLocationCount, setUniqueLocationCount] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [questionSelectionOptions, setQuestionSelectionOptions] = useState([]);
  const [locationSelectionOptions, setLocationSelectionOptions] = useState([]);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [resultAppeals, setResultAppeals] = useState([]);
  const [visits, setVisits] = useState([]);
  const [selectedVisit, setSelectedVisit] = useState(null);

  useEffect(() => {
    setUniqueLocationCount(null);
    let isMounted = true;
    const getData = async () => {
      try {
        setIsFetching(true);
        const queryParams = {
          statuses: ['APPROVED', 'DENIED', 'MODIFIED', 'UPHELD'],
          program: 'SMARTSHOP',
          isBulk: false,
        };

        // Set Location filter, if any
        if (appliedFilters.selectedLocation)
          queryParams.locations = appliedFilters.selectedLocation.value;

        // Set Question IDs filter, if any
        if (appliedFilters.selectedQuestionId) {
          queryParams.questionVersionIds =
            appliedFilters.selectedQuestionId.value;
        }

        // TODO: Setup categorie filtering once API endpoint supports it
        // Set Category filter, if any
        // if (appliedFilters.selectedCategory)
        //   queryParams.categories = appliedFilters.selectedCategory.value;

        const url = `${domain}/assessment/amendments?${queryString.stringify(
          queryParams,
        )}`;

        const { data } = await API_GET(url);

        if (isMounted) {
          setUniqueLocationCount(data?.uniqueLocationCount || 0);
          setAppeals(data?.content || []);
          setResultAppeals(data?.content || []);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted) setIsFetching(false);
      }
    };
    getData();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount, appliedFilters]);

  useEffect(() => {
    const term = searchTerm.toUpperCase();
    const results = appeals.filter((appeal) => {
      if (!term || searchObject(appeal, term)) {
        if (selectedVisit) {
          return (
            selectedVisit ===
            formatDateNoDayComma(appeal.responseInspectionTimestamp)
          );
        }
        return true;
      }
      return false;
    });

    setResultAppeals(results);
  }, [appeals, searchTerm, selectedVisit]);

  const setEffectiveQuestionsFromRoundSelection = useCallback(() => {
    if (questionSelectionOptions.length > 0) {
      return;
    }
    const questions = {};
    appeals.forEach((appeal) => {
      questions[appeal.questionVersionId] = appeal.questionInternalId;
    });
    // And finally the format question data into label and value key pairs.
    const questionIdOptions = Object.keys(questions)
      .map((key) => {
        return { value: key, label: questions[key] };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    setQuestionSelectionOptions(questionIdOptions);
  }, [appeals]);

  useEffect(() => {
    if (appeals && !isFetching) {
      if (locationSelectionOptions.length > 0) {
        return;
      }
      const locationNumbers = new Set();
      const visitMonthYear = new Set();
      appeals.forEach(({ locationNumber, responseInspectionTimestamp }) => {
        locationNumbers.add(locationNumber);
        visitMonthYear.add(formatDateNoDayComma(responseInspectionTimestamp));
      });
      const locationNumberArray = [...locationNumbers];
      setVisits([...visitMonthYear]);
      setLocationSelectionOptions(
        locationNumberArray?.map((l) => {
          return {
            label: l,
            value: l,
          };
        }),
      );
      setEffectiveQuestionsFromRoundSelection();
    }
  }, [
    appeals,
    isFetching,
    setLocationSelectionOptions,
    setEffectiveQuestionsFromRoundSelection,
  ]);

  const setIsLoadingAppeals = useCallback(() => {
    setIsFetching(true);
  }, [setIsFetching]);

  const loadAppeals = useCallback(() => {
    setRefreshCount((count) => count + 1);
  }, [setRefreshCount]);

  const searchTermChange = useCallback(
    (e) => {
      setSearchTerm(e.target.value);
    },
    [setSearchTerm],
  );

  const toggleFilterBox = useCallback(
    () => setShowFilterBox(!showFilterBox),
    [setShowFilterBox, showFilterBox],
  );

  // This is the "static"filter object for the Pending appeals page. It supplies the FilterBoxContainer.
  const appealFilters = [
    {
      filterName: 'selectedQuestionId',
      initialValue: null,
      filterLabel: 'Finding ID',
      filterType: 'dropdown',
      filterOptions: questionSelectionOptions,
    },
    {
      filterName: 'selectedLocation',
      initialValue: null,
      filterLabel: 'Store Number',
      filterType: 'search-dropdown',
      filterOptions: locationSelectionOptions,
    },
    // TODO: Setup categorie filtering once API endpoint supports it
    // {
    //   filterName: 'selectedCategory',
    //   initialValue: null,
    //   filterLabel: 'Category',
    //   filterType: 'dropdown',
    //   filterOptions: categoryFilterOptions,
    // },
  ];

  return (
    <div>
      <div className="appeals__header">
        <div>
          <Typography as="h2" className="appeals-subtitle">
            {I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_HISTORICAL_APPEALS')}
          </Typography>
          {appeals && (
            <div>
              {appeals.length}{' '}
              {I18n.t('APP_REPORT_DASHBOARD_REQUESTED_APPEALS_REQUESTS')} |{' '}
              {uniqueLocationCount}{' '}
              {I18n.t('APP_REPORT_DASHBOARD_AMENDMENT_UNIQUE_STORES')}
            </div>
          )}
        </div>
        <div className="appeals__filter-container">
          <TextField
            value={searchTerm}
            label={I18n.t('APP_SEARCH')}
            placeholder={I18n.t('APP_SEARCH')}
            onChange={searchTermChange}
            className="appeals__search"
          />
          <Dropdown
            compact
            label={I18n.t('APP_VISIT')}
            options={visits}
            value={selectedVisit}
            onChange={(selectedOption) => setSelectedVisit(selectedOption)}
          />
          <Button
            className="appeals-filters__filter-button"
            color="secondary"
            variant="outlined"
            size="md"
            onClick={toggleFilterBox}
          >
            {I18n.t('APP_REPORT_DASHBOARD_FILTERS')}
          </Button>
        </div>
      </div>
      <FilterBoxContainer
        filterArray={appealFilters}
        hideFilterBoxCallback={toggleFilterBox}
        applyFiltersCallback={setAppliedFilters}
        showFilterBox={showFilterBox}
      />
      {isFetching ? (
        <LoadingIndicator
          size="md"
          variant="page"
          style={{ margin: '6rem auto' }}
        />
      ) : resultAppeals && resultAppeals.length ? (
        <AppealsCardContainer
          appealsList={resultAppeals}
          loadingAppealsCallback={setIsLoadingAppeals}
          statusChangeCallback={loadAppeals}
          disableActionBtn={isFetching}
          isHistorical
        />
      ) : (
        <Typography as="h3" className="appeals__no-appeals-title">
          {I18n.t('APP_SMART_SHOP_NO_RESULTS_FOUND')}
          <div>{I18n.t('APP_SMART_SHOP_TRY_APPLY_DIFFERENT_FILTERS')}</div>
        </Typography>
      )}
    </div>
  );
};

export default HistoricalAppeals;

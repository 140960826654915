import { useCallback } from 'react';
import { SearchDropdown } from 'cfa-react-components';
import './FilterSearchDropdown.scss';

const renderOption = (option) => {
  const label = option.label || '';
  return {
    id: label,
    textValue: label,
    rendered: label,
  };
};

const FilterSearchDropdown = ({ filter, selectedFilter, onChange }) => {
  const { filterOptions = [], filterLabel } = filter;

  const onSearch = useCallback(
    (query) =>
      new Promise((resolve) => {
        resolve({
          canLoadMore: false,
          results: filterOptions?.filter(({ label = '' }) =>
            label.toLowerCase().startsWith(query.toLowerCase()),
          ),
        });
      }),
    [filterOptions],
  );

  return (
    <SearchDropdown
      className="filter-searchable-dropdown"
      compact
      label={filterLabel}
      onSearch={onSearch}
      value={selectedFilter}
      placeholder={filterLabel}
      renderOption={renderOption}
      onChange={onChange}
      data-testid={`search-dropdown-${filterLabel}`}
    />
  );
};

export default FilterSearchDropdown;

/* eslint-disable no-continue */
// TODO: review disabled eslint rule
import { useState, useEffect, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Dropdown,
  DatePicker,
  Tag,
  Typography,
} from 'cfa-react-components';
import I18n from '../../../../i18n/utils';
import { formatDatePickerDate } from '../../../utils/DateUtil';

import './FilterBox.scss';
import FilterSearchDropdown from './FilterSearchDropDown';

function FilterBoxContainer({
  filterArray,
  hideFilterBoxCallback,
  applyFiltersCallback,
  showFilterBox,
}) {
  // Holds filters that have been applied
  const [appliedFilters, setAppliedFilters] = useState({});
  // Holds filters that have been selected but NOT applied
  const [selectedFilters, setSelectedFilters] = useState({});

  // Used as the FilterBoxItem's onChange handler
  const setFilter = (key, value) => {
    const newObject = { ...selectedFilters };
    newObject[key] = value;
    setSelectedFilters({
      ...newObject,
      filtersSelected: true,
    });
  };

  // Used for the filter badge's onClose handler (resets one filter to its initial value)
  const resetFilter = (key, value) => {
    return () => {
      const resetObject = { ...selectedFilters };
      resetObject[key] = value;
      setSelectedFilters(resetObject);
      setAppliedFilters({
        ...resetObject,
        filtersApplied: checkOtherFiltersExist(key),
      });
    };
  };

  // function to check if other applied filters exist after one is reset
  const checkOtherFiltersExist = (skipKey) => {
    // start by assuming other filters do not exist
    let otherFiltersExists = false;

    // then loop through the array looking for other existing filters, skipping irrelevant keys
    for (const key in appliedFilters) {
      if (
        key === 'filtersApplied' ||
        key === 'filtersSelected' ||
        key === skipKey
      ) {
        continue;
      }
      if (appliedFilters[key] !== null) {
        otherFiltersExists = true;
      }
    }
    return otherFiltersExists;
  };

  const applyFilters = () => {
    // If the applied selectedFilters object is all null, then filtersApplied is false
    setAppliedFilters({
      ...selectedFilters,
      filtersApplied: true,
    });

    // When the filters are applied, then filtersSelected is false
    setSelectedFilters({
      ...selectedFilters,
      filtersSelected: false,
    });
  };

  // sets the filters equal to their "initialValue" OR a URLParam if applicable
  const initFilters = () => {
    const initObject = {};
    let initialFiltersExist = false;

    filterArray.forEach((filter) => {
      let urlParam = null;
      if (filter.getUrlParam) {
        urlParam = filter.getUrlParam;
        initialFiltersExist = true;
      }
      initObject[filter.filterName] = urlParam || filter.initialValue;
    });
    setAppliedFilters({ ...initObject, filtersApplied: initialFiltersExist });
    setSelectedFilters({ ...initObject, filtersSelected: false });
  };

  // sets the filters equal to their "initalValue"
  const resetFilters = () => {
    const initObject = {};
    filterArray.forEach((filter) => {
      initObject[filter.filterName] = filter.initialValue;
    });
    setAppliedFilters({ ...initObject, filtersApplied: false });
    setSelectedFilters({ ...initObject, filtersSelected: false });
  };

  const getTagLabel = (filter) => {
    if (
      filter.filterType === 'dropdown' ||
      filter.filterType === 'search-dropdown'
    )
      return appliedFilters[filter.filterName].label;
    if (filter.filterType === 'datePicker')
      return formatDatePickerDate(appliedFilters[filter.filterName]);
  };

  // Component Did Mount Effect
  useEffect(() => {
    initFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyFiltersCallback(appliedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]);

  return (
    <>
      {appliedFilters.filtersApplied && (
        <div
          className="filter-box__badge-container"
          data-testid="filter-box-container-badge-container"
        >
          {filterArray.length > 0 &&
            filterArray.map((filter, i) => {
              return (
                appliedFilters[filter.filterName] !== filter.initialValue && (
                  <div
                    key={`filter-box-badge-${i}`}
                    className="filter-box__badge"
                    data-testid="filter-box-container-badge"
                  >
                    <Tag
                      data-testid="filter-box-container-tag"
                      key={`filter-tag-${i}`}
                      label={getTagLabel(filter)}
                      variant="outlined"
                      onClose={resetFilter(
                        filter.filterName,
                        filter.initialValue,
                      )}
                    />
                  </div>
                )
              );
            })}
        </div>
      )}
      {showFilterBox && (
        <div
          className="filter-box"
          data-testid="filter-box-container-filter-box"
        >
          <div
            className="filter-box__headers"
            data-testid="filter-box-container-headers"
          >
            <Typography
              variant="overline1"
              data-testid="filter-box-container-filter-by-title"
            >
              {I18n.t('APP_REPORT_DASHBOARD_FILTERS_FILTER_BY')}:
            </Typography>
            <Button
              className="filter-box__x-button"
              data-testid="filter-box-container-filter-box-x-button"
              onClick={hideFilterBoxCallback}
              variant="text"
            >
              <img
                data-testid="filter-box-container-filter-box-close-img"
                alt="close"
                src={require('../../../assets/icons/X.svg').default}
                height={14}
                width={17}
              />
            </Button>
          </div>
          <div
            className="filter-box__container"
            data-testid="filter-box-container-filter-box-container"
          >
            <div
              className="filter-box__filter-container"
              data-testid="filter-box-container-filter-container"
            >
              {filterArray.length > 0 &&
                filterArray.map((filter) => {
                  const {
                    filterName = '',
                    filterLabel = '',
                    filterType = '',
                    filterOptions = [],
                  } = filter;
                  return (
                    <Fragment key={filterName}>
                      {filterType === 'search-dropdown' && (
                        <FilterSearchDropdown
                          filter={filter}
                          selectedFilter={selectedFilters[filterName]}
                          onChange={(selectedOption) =>
                            setFilter(filterName, selectedOption)
                          }
                        />
                      )}
                      {filterType === 'dropdown' && (
                        <Dropdown
                          compact
                          key={filterName}
                          label={filterLabel}
                          options={filterOptions}
                          placeholder={filterLabel}
                          value={selectedFilters[filterName] || null}
                          getOptionId={({ value }) => value}
                          renderOption={({ label }) => label}
                          onChange={(selectedOption) =>
                            setFilter(filterName, selectedOption)
                          }
                        />
                      )}
                      {filterType === 'datePicker' && (
                        <DatePicker
                          className="filter-box__date-picker"
                          compact
                          label={filterLabel}
                          value={selectedFilters[filterName]}
                          onChange={(value) => setFilter(filterName, value)}
                        />
                      )}
                    </Fragment>
                  );
                })}
            </div>
            <div
              className="filter-box__button-container"
              data-testid="filter-box-container-button-container"
            >
              {appliedFilters.filtersApplied && (
                <Button
                  className="filter-box__reset-button"
                  data-testid="filter-box-container-reset-button"
                  color="secondary"
                  variant="text"
                  size="lg"
                  onClick={resetFilters}
                >
                  {I18n.t('APP_REPORT_DASHBOARD_FILTERS_RESET')}
                </Button>
              )}
              <Button
                className="filter-box__apply-button"
                data-testid="filter-box-container-apply-button"
                color="secondary"
                size="lg"
                disabled={!selectedFilters.filtersSelected}
                onClick={applyFilters}
              >
                {I18n.t('APP_REPORT_DASHBOARD_FILTERS_APPLY')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

FilterBoxContainer.propTypes = {
  filterArray: PropTypes.array.isRequired,
  hideFilterBoxCallback: PropTypes.func.isRequired,
  applyFiltersCallback: PropTypes.func.isRequired,
  showFilterBox: PropTypes.bool.isRequired,
};

export default FilterBoxContainer;
